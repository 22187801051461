import jQuery from "jquery";

const headerpos = () => {
    if(jQuery(document).scrollTop() > 0) {
        jQuery(".siteheader").addClass('slim')
    } else {
        jQuery(".siteheader").removeClass('slim')
    }
}

export const header = () => {
    headerpos()
    jQuery(window).on('scroll', function(){
        headerpos()
    })
}