import { faq } from "./accordion-faq";
import { counter } from "./counter";
import { form } from "./form";
import { header } from "./header";
import { initIsotope } from "./isotope";
import { menu } from "./menu";
import { sliders } from "./sliders";
import { starRating } from "./star-rating";
import jQuery from 'jquery'

header()
counter()
faq()
form()
starRating()
sliders()
initIsotope()
menu()

jQuery("a[href^='#']").on("click", function () {
    const target = jQuery(this).attr('href')
    jQuery('html, body').animate({
        scrollTop: jQuery(target).offset().top - jQuery(".siteheader").height()
    }, 1000)
    return false
})

const animateElements = document.querySelectorAll(".animate");

const observer = new IntersectionObserver(entries => [
    entries.forEach(entry => {
        if (entry.isIntersecting) {
        jQuery(entry.target).addClass("active");
        observer.unobserve(entry.target);
        }
    })
], {
    threshold: 0.3
});
animateElements.forEach(animateElement => {
    observer.observe(animateElement);
});