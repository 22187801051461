import jQuery from "jquery";
import 'jquery-validation';

jQuery.validator.addMethod(
    "regex",
    function (value, element, regexp) {
        return this.optional(element) || regexp.test(value);
    },
    "Please check your input."
);

export const form = () => {
    const contactUsForm = jQuery("form[name='contactUsForm']");
    contactUsForm.validate({
        rules: {
            comments: {
                required: true,
                minlength: 25
            },
            fullname: {
                required: true,
                regex: /^([A-Z a-z.]+)$/,
                minlength: 2
            },
            emailid: {
                required: true,
                regex: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,12})+$/
            },
            phonenumber: {
                required: true,
                regex: /^(?:(?:\+|0{0,2})91)?\d{6,11}$/
            },
        },
        messages: {
            comments: "Please describe your interest",
            fullname: "Please enter your full name",
            emailid: "Please enter your valid email ID!",
            phonenumber: "Provide your phone number for contacting you"
        },
        errorElement: 'span',
        errorPlacement: function (error, element) {
            error.appendTo(element.parents('.inputHolder'));
        }
    });
    let contactUsFormBtn = jQuery('.btnwrapper');
    // let contactUsFormLoader = jQuery('.form-loader');
    // let contactUsFormResponse = jQuery('.response-output');
    contactUsForm.on('submit', function () {
        contactUsFormBtn.hide();
        // contactUsFormLoader.css('display', 'flex');
        if (contactUsForm.valid()) {
            const formData = {};
            jQuery(this).find('input, select, textarea').each(function () {
                if (jQuery(this).attr('type') === 'submit') {
                    return
                }
                if (jQuery(this).attr('type') === 'checkbox') {
                    formData[jQuery(this).attr('name')] = jQuery(`input[name="${jQuery(this).attr('name')}"]:checked`).map(function () {
                        return this.value;
                    }).get().join();
                    return;
                } else {
                    formData[jQuery(this).attr('name')] = jQuery(this).val();
                }
            });
            jQuery.ajax({
                url: site_url + '/api/contact-us.php',
                data: formData,
                method: "post",
            })
            .done(() => {
                contactUsFormBtn.hide();
                // contactUsFormLoader.hide();
                // contactUsFormResponse.slideDown('slow', function () {
                    setTimeout(function () {
                        // contactUsFormResponse.slideUp('slow', function () {
                            contactUsForm.get(0).reset();
                            contactUsFormBtn.show();
                            // contactUsFormLoader.hide();
                        // });
                    }, 5000)
                // });
            })
        } else {
            contactUsFormBtn.show();
            // contactUsFormLoader.hide();
        }
        return false;
    });
}