export const starRating = () => {
    document.addEventListener('DOMContentLoaded', function () {
        const starRating = document.querySelectorAll('.star-rating');

        if(starRating){
            starRating.forEach(function (starRating) {
                const maxRating = 5;
                let initialRating = parseFloat(starRating.getAttribute('data-rating'));
        
                for (let i = 1; i <= maxRating; i++) {
                    const star = document.createElement('span');
                    star.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 2l2.4 7.2h7.6l-6 4.8 2.4 7.2-6-4.8-6 4.8 2.4-7.2-6-4.8h7.6z"/><path class="bg" d="M12 2l2.4 7.2h7.6l-6 4.8 2.4 7.2-6-4.8-6 4.8 2.4-7.2-6-4.8h7.6"/></svg>';
                    // star.addEventListener('mouseover', function () {
                    //     setRating(i);
                    // });
                    // star.addEventListener('click', function () {
                    //     starRating.setAttribute('data-rating', i);
                    //     initialRating = i;
                    // });
                    // console.log(i)
                    starRating.appendChild(star);
                }
        
                setRating(initialRating);
        
                function setRating(rating) {
                    const stars = starRating.querySelectorAll('svg');
                    stars.forEach((star, index) => {
                        const filled = index < Math.floor(rating);
                        const partiallyFilled = index === Math.floor(rating) && rating % 1 !== 0;
                        star.classList.toggle('active', filled);
                        star.classList.toggle('partially-filled', partiallyFilled);
                    });
                }
            })
        }
    });
}