import 'slick-carousel';
import jQuery from 'jquery';

export const sliders = () => {
    document.addEventListener('DOMContentLoaded', function () {
        const slider = jQuery('.mobilescreenSliders');
        const syncCounter = jQuery('.slidecounter');
        if(slider){
            slider.slick({
                dots: false,
                infinite: false,
                speed: 300,
                slidesToShow: 3,
                arrows: true,
                autoplay: false,
                autoplaySpeed: 2000,
                centerMode: true,
                centerPadding: '0px',
                pauseOnHover: true,
                asNavFor: '.slidecounter',
                prevArrow: '<button class="slick-btn prev"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 57 57" style="enable-background:new 0 0 57 57" xml:space="preserve"><circle cx="28.5" cy="28.5" r="28.5" style="fill:#fff"/><path d="M36.3 15.5 20.7 28.1l15.5 12.6" style="fill:none;stroke:#36f;stroke-width:2"/></svg></button>',
                nextArrow: '<button class="slick-btn next"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 57 57" style="enable-background:new 0 0 57 57" xml:space="preserve"><circle cx="28.5" cy="28.5" r="28.5" style="fill:#fff"/><path d="m20.7 40.7 15.5-12.6-15.5-12.6" style="fill:none;stroke:#36f;stroke-width:2"/></svg></button>',
                responsive: [
                    {
                        breakpoint: 1024,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                            infinite: true
                        }
                    },
                    {
                        breakpoint: 767,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                        }
                    }
                ]
            });
            syncCounter.slick({
                asNavFor: '.mobilescreenSliders',
                dots: false,
                infinite: false,
                speed: 300,
                slidesToShow: 3,
                arrows: false,
                autoplay: false,
                autoplaySpeed: 2000,
                centerMode: true,
                pauseOnHover: true,
                focusOnSelect: true,
                responsive: [
                    {
                        breakpoint: 1024,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                            infinite: true
                        }
                    },
                    {
                        breakpoint: 767,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                        }
                    }
                ]
            })
        }

        const testimonialSlider = jQuery('.testimonialSlider');
        if(testimonialSlider){
            testimonialSlider.slick({
                dots: false,
                infinite: true,
                speed: 300,
                slidesToShow: 4,
                arrows: true,
                autoplay: true,
                autoplaySpeed: 2000,
                pauseOnHover: true,
                prevArrow: '<button class="slick-btn prev"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.7 26.7" style="enable-background:new 0 0 17.7 26.7" xml:space="preserve"><path style="fill:#fff" d="M16.5 26.7 0 13.3 16.5 0l1.2 1.6L3.2 13.3l14.5 11.9z"/></svg></button>',
                nextArrow: '<button class="slick-btn next"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.7 26.7" style="enable-background:new 0 0 17.7 26.7" xml:space="preserve"><path style="fill:#fff" d="m1.3 26.7 16.4-13.4L1.3 0 0 1.6l14.6 11.7L0 25.2z"/></svg></button>',
                responsive: [
                    {
                        breakpoint: 1199,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3,
                            infinite: true
                        }
                    },
                    {
                        breakpoint: 991,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                            infinite: true
                        }
                    },
                    {
                        breakpoint: 767,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                        }
                    }
                ]
            });
        }
    });
}