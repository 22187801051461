import Isotope from 'isotope-layout';
import jQuery from "jquery"

export const initIsotope = () => {
    const ele = jQuery('.work-filter-wrap');
    if(ele.length > 0){
        const $grid = new Isotope('.work-filter-wrap', {
            itemSelector: '.work-listing',
            layoutMode: 'fitRows'
        });
        jQuery('.work-nav button').on('click',function(){
            const filterValue = jQuery(this).attr('data-filter');
            jQuery('.work-nav button').removeClass("active-filter")
            jQuery(this).addClass("active-filter")
            $grid.arrange({ filter: filterValue });
            console.log(filterValue)
        });
        jQuery(".work-filter-wrap img").one("load", function() {
            $grid.isotope('layout');
        })
    }
}
