import jQuery from "jquery";
import checkNumber from "./checknumber";

export const counter = () => {
    const counterElements = document.querySelectorAll("[data-counter]");

   const counterObserver = new IntersectionObserver(entries => {
      entries.forEach(entry => {
         if (entry.isIntersecting) {
            if (!jQuery(entry.target).hasClass('active')) {
               const num = parseFloat(jQuery(entry.target).html());
               jQuery(entry.target).text("0");
               const numberCheck = checkNumber(num);
               let steps = 0;
               let isFloat = false;
               let decimalIndex = 0;
               let decimalVal = 0;
               if (numberCheck) {
                  steps = num;
               } else {
                  steps = Math.round(num);
                  isFloat = true;
                  decimalIndex = num.toString().split(".")[1].length;
                  decimalVal = num.toString().split(".")[1];
               }
               let timer = steps > 2000 ? 10 : 2000 / steps;
               let counterPoint = 0;
               let counterString = 0;
               let decimalIncrementVal = decimalVal / steps;
               const counter = setInterval(() => {
                  counterPoint++;
                  counterString = counterString + decimalIncrementVal;
                //   let incrementVal = 1;
                //   let decimalCounter = 0;
                  let counterValue = 0;
                  if (isFloat) {
                     counterValue = counterPoint + (counterString / Math.pow(10,
                        decimalIndex));
                  } else {
                     counterValue = counterPoint;
                  }
                  let counterText = `${counterValue.toFixed(decimalIndex)}`;
                  if (jQuery(entry.target).data('suffix')) {
                     counterText = `${counterText}${jQuery(entry.target).data('suffix')}`;
                  }
                  if (jQuery(entry.target).data('prefix')) {
                     counterText = `${jQuery(entry.target).data('prefix')}${counterText}`;
                  }
                  jQuery(entry.target).text(counterText);
                  if (counterPoint === steps) {
                     clearInterval(counter);
                  }
               }, timer);
               jQuery(entry.target).addClass("active");
               counterObserver.unobserve(entry.target);
            }
         }
      });
   }, {
      threshold: 0.3
   });

   counterElements.forEach(counterElement => {
      counterObserver.observe(counterElement);
   });
}