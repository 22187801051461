import jQuery from "jquery";

export const menu = function(){
    jQuery(".mobileBurger").on("click", function() {
        console.log("Mobile menu clicked");
        jQuery("body").toggleClass('open')
    })
    jQuery(".siteheader .backdrop").on("click", function() {
        jQuery("body").removeClass('open')
    })
}