import jQuery from "jquery";

export const faq = () => {
    jQuery(".faqlist li").each(function(){
        if(!jQuery(this).hasClass('active')){
            jQuery(this).find('p').slideUp()
        }
        jQuery(this).on('click',function(){
            if(jQuery(this).hasClass('active')){
                return false
            }
            jQuery(".faqlist li").removeClass('active')
            jQuery(this).addClass('active')
            jQuery(".faqlist li p").slideUp()
            jQuery(this).find('p').slideDown()
        })
    })
}